<template>
    <div>
        <el-row justify="space-between">
            <el-col :sm="24" :md="12" :lg="12" v-if="checkUsrPermissions('ZCF Service Plan Create')">
                <el-button type="primary" @click="$router.push('/zcf/service/plans/create')">Create new Plan</el-button>
            </el-col>
            <el-col :sm="24" :md="9" :lg="9" class="" v-if="authUser.user_type == 'executive'">
                <el-select @change="getItems()" class="m-2" v-model="center_id" placeholder="Select Center" size="large">
                <el-option
                    v-for="item in centers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
            </el-col>
            <el-col :sm="12" :md="3" :lg="3" class="text-right">
                <el-button type="warning" size="large" @click="getItems" circle>
                <font-awesome-icon icon="fa-solid fa-rotate-right"/>
                </el-button>
            </el-col>
        </el-row>

        <el-row :gutter="20" align="middle">
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                <p class="demonstration">Schedule Start date</p>
                <el-date-picker
                    v-model="filter.start_date"
                    type="date"
                    class="w-100"
                    placeholder="Pick a day"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    size="large"
                />
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                <p class="demonstration">Schedule End date</p>
                <el-date-picker
                    v-model="filter.end_date"
                    type="date"
                    class="w-100"
                    placeholder="Pick a day"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    size="large"
                />
            </el-col>

            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                <p class="demonstration">Select Status</p>
                <el-select @change="getItems()" class="m-2" v-model="filter.status" placeholder="Select Status" size="large">
                    <el-option label="Select One" value=""/>
                    <el-option label="Pending" value="pending"/>
                    <el-option label="Approved" value="approved"/>
                    <el-option label="Rejected" value="rejected"/>
                    <el-option label="Served" value="served"/>
                </el-select>
            </el-col>
            
            
            <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4" class="mt-40">
                <el-button type="primary" size="large" @click="getItems" round
                >Filter
                </el-button
                >
                <el-button type="danger" size="large" @click="clear" round
                >Clear
                </el-button
                >
            </el-col>
        </el-row>

        <br/>

        <el-table :data="items" border v-loading="loading" >
            <el-table-column prop="patient" label="Patient"></el-table-column>
            <el-table-column prop="doctor" label="Doctor"></el-table-column>
            <el-table-column prop="assistant" label="Assistant"></el-table-column>
            <el-table-column prop="service_type" label="Service Type"></el-table-column>
            <el-table-column prop="feet" label="Feet"></el-table-column>
            <el-table-column prop="schedule_date" label="Schedule Date"></el-table-column>
            <el-table-column prop="schedule_time" label="Schedule Time"></el-table-column>
            <el-table-column prop="status" label="Status">
                <template #default="scope">
                    <el-tag class="ml-2" v-if="scope.row.status == 'pending'" >{{  scope.row.status }}</el-tag>
                    <el-tag class="ml-2" v-else-if="scope.row.status == 'approved'" type="success">{{  scope.row.status }}</el-tag>
                    <el-tag class="ml-2" v-else-if="scope.row.status == 'rejected'" type="danger">{{  scope.row.status }}</el-tag>
                    <el-tag class="ml-2" v-else type="warning">{{  scope.row.status }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="created_at" label="Created date"></el-table-column>
            <!-- <el-table-column prop="actions" label="Actions">
                <template #default="scope">
                    <el-button type="warning" circle @click="$router.push('/welfare/application/update/'+scope.row.uid)" v-if="scope.row.status=='pending' && checkUsrPermissions('Update Welfare Application')">
                        <el-icon>
                            <font-awesome-icon icon="fa fa-edit"/>
                        </el-icon>
                    </el-button>
                </template>
            </el-table-column> -->
        </el-table>
        <el-pagination
            v-model:current-page="currentPage"
            :page-size="per_page"
            layout="total, prev, pager, next"
            :total="total_count"
            @current-change="getItems"
        />

    </div>
</template>
<script>
import axios from 'axios';
import { CenterMixins } from '@/mixins/CenterMixins';
import { mapGetters } from 'vuex';
export default {
    name: 'ServicePlan',
    mixins: [CenterMixins],
    data() {
        return {
            items: [],
            total_count: 0,
            currentPage: 0,
            per_page: 20,
            filter: {
                start_date: null,
                end_date: null,
                status: ''
            },
        }
    },
    created() {
        this.getItems();
    },
    computed: {
        ...mapGetters('auth', ['userInstitute', 'authUser']),
    },
    methods: {
        getItems(val){
            if (val) {
                const offset = val - 1;
                var correctValue = offset * this.per_page;
            }

            const filter = `?limit=${this.per_page}&offset=${correctValue || 0}
            ${this.filter.start_date ? '&start_date='+this.filter.start_date : ''}
            ${this.filter.end_date ? '&end_date='+this.filter.end_date : ''}${this.filter.status ? '&status='+this.filter.status : ''}`;

            axios.get(`api/v2/zcf-service/plans${filter}`)
            .then(res => {
                this.items = res.data.data;
                this.total_count = res.data.total_count;
            })
        },
        clear() {
            this.filter = {};
            this.getItems();
        }
    }
}
</script>
<style>
.w-100 {
  width: 100% !important;
}
</style>